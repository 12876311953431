<template>
  <div class="flex min-h-full flex-col">
    <NavigationBar />
    <VNotification
      :key="JSON.stringify(notification)"
      class="absolute top-22 right-4 w-96"
      :show="!!notification"
      :delay="notification ? notification.delay : 0"
      :type="notification ? notification.type : ''"
      @dismissed="setNotification(null)"
    >
      <template #title>{{ notification && notification.title }}</template>
      <template v-if="notification && notification.text">{{ notification.text }}</template>
    </VNotification>
    <div class="flex-grow" :class="$attrs.class"><slot /></div>
    <FooterDefault />
  </div>
</template>

<script lang="ts" setup>
const { notification, setNotification } = useGlobalNotification()
</script>
